import styles from "./styles/ContainerItem.module.css";
const ContainerItem = ({t}) => {
  return (
    <div className={styles.item}>
      <div className={styles.base} />
      <div className={styles.callUs}>{t(`Support.chiamaci`)}</div>
      <div className={styles.icon}>
        <div className={styles.iconCall}>
          <div className={styles.iconCall} />
          <img className={styles.pathIcon} alt="" src="/path.svg" />
        </div>
      </div>
      <div className={styles.callUsToContainer}>
      <p className={styles.inviaciUnaMail} dangerouslySetInnerHTML={{ __html: t(`Support.chiamaciDiv`).replace(/\n/g, "<br>") }}>
          </p>
      </div>
      <div className={styles.div}>+39 (02) 12345678</div>
    </div>
  );
};

export default ContainerItem;
