import { useRef, useState, useEffect, useContext } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import styles from "./styles/Registration.module.css";
import { BaseUrlContext } from "../context/BaseUrlContext";
import { ClipLoader } from "react-spinners";
import SeedPage from "../components/SeedPage";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = "/api/createUser";
const RESTORE_URL = "/api/restoreUser"

const Registration = ({t,shouldImportMasternode}) => {
  const { baseUrl } = useContext(BaseUrlContext);
  const userRef = useRef();
  const errRef = useRef();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [recoverMessage, setRecoverMessage] = useState("");
  const [registrationMessagge, setRegistrationMessagge] = useState("");

  const [seed, setSeed] = useState("");
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");


  useEffect(() => {
    userRef.current.focus();
  }, []);


  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [pwd, matchPwd]);


  const handleSubmit = async (e) => {
    setLoading(true)
    console.log(process.env.REACT_APP_NETWORK_TYPE)
    e.preventDefault();
    // if button enabled with JS hack
    const v2 = PWD_REGEX.test(pwd);
    console.log(v2)
    if (!v2) {
      setErrMsg(t("ServerErrors.invalidEntry"));
      return;
    }
    if(shouldImportMasternode){
      try {
        const response = await axios.post(
          baseUrl+RESTORE_URL,
          {
            nome: name,
            cognome: surname,
            email:email,
            password:pwd,
            network: process.env.REACT_APP_NETWORK_TYPE
          },
          {
            headers: {
              Authorization: `Bearer ${shouldImportMasternode}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        if(response.data.result !== false ){
          setEmail("");
          setPwd("");
          setMatchPwd("");
          navigate("/login")
          setLoading(false);
        }else{
            setErrMsg(response.data.message);
            setLoading(false);
          }
      } catch (err) {
        console.log(err)
        if(err.response){
          setErrMsg(err);
        } else{
          setErrMsg(err.message)
        }
        errRef.current.focus();
      }
    }else{
    try {
      const response = await axios.post(
        baseUrl+REGISTER_URL,
        {
          nome: name,
          cognome: surname,
          email:email,
          password:pwd,
          network: process.env.REACT_APP_NETWORK_TYPE,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SECRET_APP}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if(response.data.result !== false){
          setSeed(response.data.data.mnemonic);
          setEmail("");
          setPwd("");
          setMatchPwd("");
          setRegistrationMessagge("Registrazione avvenuta con successo, adesso visualizzerai la tua seed phrase.")
          setSuccess(true)
          setLoading(false);
        }else{
          setErrMsg(response.data.message);
          setLoading(false);
        }
    } catch (err) {
      console.log(err)
      if(err.response){
        setErrMsg(err);
      } else{
        setErrMsg(err.message)
      }
      errRef.current.focus();
    }
  }
  };

  return (
    <>
    <Helmet>
        <title>{t("Registration.metaTitle")}</title>
        <meta name="description" content={t("Registration.metaDescription")} />
    </Helmet>
    {loading &&
      <div className={styles.specific_page}>
        <div className={styles.divLoader}>
            <ClipLoader size={50} color="#001e66" />
            <p style={{ marginLeft: 25, marginTop: 8, fontSize:30 }}> Loading...</p>
        </div>
      </div>}
    {success?
    <SeedPage seed={seed} t={t}/>
      :
        <div className={styles.specific_page}>
          <section className={styles.watermark}>
            <p
              ref={errRef}
              className={errMsg ? styles.errmsg : styles.offscreen}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <form onSubmit={handleSubmit} className={styles.formDiv}>
              <h2 style={{ textAlign: "center" }}>
                {t("Registration.signup")}
              </h2>

             

              <label htmlFor="name" style={{ color: "#9f8b3b" }}>
              {t("Registration.name")}
                
              </label>
              <input
                type="text"
                id="name"
                className={styles.labelDiv}
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                aria-describedby="uidnote"
              />


              <label htmlFor="surname" style={{ color: "#9f8b3b" }}>
              {t("Registration.surname")}
            
              </label>
              <input
                type="text"
                id="surname"
                className={styles.labelDiv}
                autoComplete="off"
                onChange={(e) => setSurname(e.target.value)}
                value={surname}
                required
                aria-describedby="uidnote"
              />
             
              <label htmlFor="email" style={{ color: "#9f8b3b" }}>
                Email:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validEmail ? styles.valid : styles.hide}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={
                    validEmail || !email ? styles.hide : styles.invalid
                  }
                />
              </label>
              <input
                type="text"
                id="email"
                className={styles.labelDiv}
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
              <p
                id="uidnote"
                className={
                  emailFocus && email && !validEmail
                    ? styles.instructions
                    : styles.offscreen
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                {t("Registration.emailHandle")}
              </p>

              <label htmlFor="password" style={{ color: "#9f8b3b" }}>
                Password:
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validPwd ? styles.valid : styles.hide}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validPwd || !pwd ? styles.hide : styles.invalid}
                />
              </label>
              <input
                type="password"
                id="password"
                className={styles.labelDiv}
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <p
                id="pwdnote"
                className={
                  pwdFocus && !validPwd ? styles.instructions : styles.offscreen}
              >
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t(`Registration.passwordHandle`)}{" "}
                
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>

              <label htmlFor="confirm_pwd" style={{ color: "#9f8b3b" }}>
                {t("Registration.confirmPassword")}
                <FontAwesomeIcon
                  icon={faCheck}
                  className={
                    validMatch && matchPwd ? styles.valid : styles.hide
                  }
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={
                    validMatch || !matchPwd ? styles.hide : styles.invalid
                  }
                />
              </label>
              <input
                type="password"
                id="confirm_pwd"
                className={styles.labelDiv}
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <p
                id="confirmnote"
                className={
                  matchFocus && !validMatch
                    ? styles.instructions
                    : styles.offscreen
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                {t("Registration.confirmPasswordHandle")}
              </p>

              <button
                className={`${
                  !validPwd || !validMatch || !validEmail
                    ? styles.buttonDivDisabled
                    : styles.buttonDiv
                }`}
                disabled={
                  !validPwd || !validMatch || !validEmail
                    ? true
                    : false
                }
              >
                {t("Registration.signup")}
              </button>
              <p>
                {t("Registration.alreadyRegistered")}
                <span className={styles.line}>
                  <a href="/login" style={{marginLeft:8}}>{t("Login.signin")}</a>
                </span>
              </p>
            </form>
          </section>
        </div>
    }
    </>
  );
};

export default Registration;
