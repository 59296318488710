import { useEffect, useMemo, useState } from "react";
import styles from "./styles/CryptoContainer.module.css";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";

const CryptoContainer = ({
  cryptoPrice,
  address,
  name,
  index,
  cryptoName,
  handleAddressMap,
  handleIndex,
  propLeft,
  handleViewCard,
  handleCurrentCoinType,
  singleBalance,
  singleBalanceLoading,
  balances,
  loading,
}) => {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function truncateNumber(number, decimalPlaces) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.trunc(number * multiplier) / multiplier;
  }

 
  const spanStyleLogic = `${styles.span} ${isHovered ? styles.spanHover : ""}`;
  const bitcoinClassStyleLogic = `${styles.bitcoin} ${
    isHovered ? styles.bitcoinHover : ""
  }`;
  const bilancioClassStyleLogic = `${styles.bilancio} ${
    isHovered ? styles.bilancioHover : ""
  }`;
  const prezzoClassStyleLogic = `${styles.prezzo} ${
    isHovered ? styles.prezzoHover : ""
  }`;
  const pClassStyleLogic = `${styles.p} ${isHovered ? styles.pHover : ""}`;
  const parsedBalances = balances && balances !== "" ? JSON.parse(balances) : {};

  const handleButtonClick = () => {
    setShowWarning(true);
    setTimeout(() => {
      setShowWarning(false);
    }, 8000); // Nascondi il warning dopo 3 secondi
  };

  return (
    <div className={styles.riqCoin}>
      {/*showWarning && (
        <div className={`${styles.warning} ${styles.active}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontWeight: 700,
              justifyContent: "center",
              color: "red",
            }}
          >
            Warning:
          </div>
          Seleziona un indirizzo{" "}
          <span style={{ color: "#001e66", fontWeight: 700 }}>
            {cryptoName}
          </span>{" "}
          prima di effettuare una transazione!
        </div>
          )*/}

      <div
        className={styles.riqCoinChild}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (!loading) {
          handleIndex(index)
          handleViewCard();
          handleAddressMap(index, cryptoName, address);
          handleCurrentCoinType(cryptoName);
        }
      }}
      >
        <img className={styles.btcIcon} alt="" src={cryptoPrice} />
      </div>
      <div className={bitcoinClassStyleLogic}>
        {name} <sup className={styles.supStyle}>{index.toString()}</sup>
      </div>
      {cryptoName == "BTC" ||
        (cryptoName == "XMR" && (
          <div className={styles.bilancio0Container}>
            <span className={styles.bilancio0Valor}>
              <span className={bilancioClassStyleLogic}>
                {t("Dashboard.noSupport")}
              </span>
            </span>
          </div>
        ))}
      <div className={styles.bilancio0Container}>
        <span className={styles.bilancio0Valor}>
          {cryptoName == "BTC" && (
            <span className={bilancioClassStyleLogic}>
              {t("Dashboard.noSupport")}
            </span>
          )}
          {cryptoName == "XMR" && (
            <span className={bilancioClassStyleLogic}>
              {t("Dashboard.noSupport")}
            </span>
          )}
          {cryptoName === "ETH" && (
            <>
              <span className={bilancioClassStyleLogic}>
                {t("Dashboard.support")}
              </span>
              <span className={spanStyleLogic}>{"ERC-20"}</span>
            </>
          )}
          {cryptoName === "BNB" && (
            <>
              <span className={bilancioClassStyleLogic}>
                {t("Dashboard.support")}
              </span>
              <span className={spanStyleLogic}>{"BEP-20"}</span>
            </>
          )}
          {cryptoName === "SOL" && (
            <>
              <span className={bilancioClassStyleLogic}>
                {t("Dashboard.noSupport")}
              </span>
            </>
          )}
        </span>

        <span className={`${styles.addressVal} ${isHovered ? styles.addressValHover : ""}`}>
          <span style={{fontWeight:"normal"}}>Address:</span>{"  "}{address.substring(0, 4) +"..." +address.substring(address.length - 5)}
        </span>
      </div>

      <div className={styles.prezzo2762233} style={
         (cryptoName!=="XMR" && cryptoName!=="BTC")?(
          parsedBalances?.[cryptoName] && parsedBalances?.[cryptoName]?.[index] !== undefined & parsedBalances?.[cryptoName]?.[index]?.balance===0 ? {left:415}: null):(
          parsedBalances?.[cryptoName] && parsedBalances?.[cryptoName]?.[index] !== undefined & parsedBalances?.[cryptoName]?.[index]?.balance.balance=== 0 ? {left:415}: null)
         }>
        <p className={prezzoClassStyleLogic}>
          {t("Dashboard.prezzoLabel")}
        </p>
        <>
        {(singleBalanceLoading.loading && singleBalanceLoading.cryptoName===cryptoName)? (
            <BeatLoader size={8} color="#FFF" />
        ): (
          <p className={pClassStyleLogic}>
            {singleBalance}
          </p>
        )}
        </>
        {loading ? (

          <BeatLoader size={8} color="#FFF" />
        ) : (
          <p className={pClassStyleLogic}>
            {
            (cryptoName!=="XMR" && cryptoName!=="BTC")?(
            parsedBalances?.[cryptoName] && parsedBalances?.[cryptoName]?.[index] !== undefined & parsedBalances?.[cryptoName]?.[index]?.balance!== 0
              ? truncateNumber(parsedBalances?.[cryptoName]?.[index].balance,6)
              : 0
              ? parsedBalances?.[cryptoName]?.[0].balance
              : "0"):(
            parsedBalances?.[cryptoName] && parsedBalances?.[cryptoName]?.[index] !== undefined & parsedBalances?.[cryptoName]?.[index]?.balance.balance!== 0
              ? truncateNumber(parsedBalances?.[cryptoName]?.[index].balance.balance,6)
              : 0
              ? parsedBalances?.[cryptoName]?.[0].balance.balance
              : "0")}{" "}
            {cryptoName}
          </p>
        )}
  
      </div>
    </div>
  );
};

export default CryptoContainer;
