import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./styles/TransferCard.module.css";
import { useAuth } from "../context/AuthProvider";
import { BaseUrlContext } from "../context/BaseUrlContext";
import { ClipLoader } from "react-spinners";
import SuccessPop from "./SuccessPop";
import FailurePopUp from "./FailurePopUp";

const CryptoTransfer = ({
  returnBackForCrypto,
  handleViewCard,
  t,
  index,
  cryptoName,
  balances,
  addresses,
  handleSuccessClick
}) => {
  const [destinationAddress, setDestinationAddress] = useState("");
  const { token } = useAuth();
  const { baseUrl } = useContext(BaseUrlContext);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const parsedBalances = balances && balances !== "" ? JSON.parse(balances) : {};

  const EXCHANGE_COIN_URL = "/api/exchangeCoinBetweenSameBc";
  const handleDestinationAddressChange = (event) => {
    setDestinationAddress(event.target.value);
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setAmount(value);
    }
  };

  function truncateNumber(number, decimalPlaces) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.trunc(number * multiplier) / multiplier;
  }

  const handleExchange = async () => {
    setLoading(true);
    await exchangeCoinBetweenBlockchain();
    setLoading(false);
  };

  const exchangeCoinBetweenBlockchain = async () => {
    try {
      const response = await axios.post(
        baseUrl + EXCHANGE_COIN_URL,
        {
          cointype: cryptoName,
          fromaddress: addresses[cryptoName][index],
          toaddress: destinationAddress,
          amount: amount,
          network: "TESTNET",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Assicurati di includere "Bearer " prima del token
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setSuccess(true);
    } catch (err) {
      if(err.response)
        setFailure(err.response.data.message)
      else{
        setFailure("Network Error, check your connection")
      }
    }
  };

  const handleSuccess = async() => {
    await handleViewCard(true,cryptoName,addresses[cryptoName][index]);
    setSuccess(!success);
    handleSuccessClick()
  };

  const handleFailure = async () => {
    await handleViewCard();
    setFailure(!failure);
    //handleSuccessClick()
  };

  return (
    <>
      {success && (
        <SuccessPop
          message={t("SuccessPopUp.TransferTitle")}
          handleSuccess={handleSuccess}
          t={t}
        />
      )}
      {failure && (
        <FailurePopUp
          message={failure}
          handleFailure={handleFailure}
          t={t}
        />
      )}
      {!loading ? (
        <div className={styles.containerCard}> 
          <div className={`${styles.connectionCard} connecting`} style={cryptoName==="XMR" ?{height:415}: null}>
            <div className={styles.rowConnection}>
              <div>
                <FiArrowLeft
                  className={styles.firstRowIcon}
                  onClick={returnBackForCrypto}
                />
              </div>
              <div>
                <p>{t("OperationCard.cardCryptoTitle")}</p>
              </div>
              <div>
                <AiOutlineClose
                  className={styles.firstRowIcon}
                  onClick={handleViewCard}
                />
              </div>
            </div>
            <div className={styles.columnConnection}>
              <div className={`${styles.flexDiv} cont`} style={cryptoName==="XMR" ? {height:130}: null}>
              
                <p className={styles.pStyle}>
                  {t("TransferCard.chooseSend")}{" "}
                  <span style={{ color: "#001e66", fontWeight: 700 }}>
                    {t("TransferCard.cryptoCurrency")}
                  </span>{" "}
                  {t("TransferCard.inYourWallet")}{" "}
                  {t("TransferCard.insertDestinationAddressAndAmount")}
                </p>
                <div className={styles.flexDiv}>
                  <div className={styles.flexDiv}>
                    <label
                      htmlFor="destinationAddress"
                      className={styles.label}
                    >
                      {t("TransferCard.destinationAddress")}
                    </label>
                    <input
                      type="text"
                      id="destinationAddress"
                      value={destinationAddress}
                      onChange={handleDestinationAddressChange}
                      className={styles.input}
                      placeholder={t("OperationCard.placeholderAddr")}
                      required
                    />
                    <div className={styles.textMuted}>
                      *{t("TransferCard.validAddress")}
                      <span style={{ color: "#001e66", fontWeight: 700 }}>
                        {cryptoName.toUpperCase()}
                      </span>
                      {t("TransferCard.valid")}
                    </div>
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.flexDiv}>
                      <label
                        htmlFor="amount"
                        className={styles.label}
                        style={{ marginTop: 20 }}
                      >
                        {t("TransferCard.amount")}
                      </label>
                      <input
                        type="text"
                        id="amount"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder={t("OperationCard.placeholderAmount")}
                        className={styles.input}
                        required
                      />
                      <div className={styles.textMuted}>
                        *{(cryptoName !== "XMR" && cryptoName!== "BTC") ?t("TransferCard.currentAmount"):t("TransferCard.moneroCurrentUnlockedAmount")}{" "}<span style={{ color: "#001e66",fontWeight:700 }}>{(cryptoName !== "XMR" && cryptoName!== "BTC") ? (parsedBalances[cryptoName][index].balance === 0 ? "0" : truncateNumber(parsedBalances[cryptoName][index].balance,6))
                        :  (parsedBalances[cryptoName][index].balance.unlockedBalance=== 0 ? "0": truncateNumber(parsedBalances[cryptoName][index].balance.unlockedBalance,6))}
                        {" "}{cryptoName}</span>
                      
                      </div>
                        {(cryptoName === "XMR" || cryptoName === "BTC") && (
                        <>
                        <div className={styles.textMuted}>
                          *{t("TransferCard.moneroCurrentAmount")}{" "}
                          <span style={{ color: "#001e66", fontWeight: 700 }}>
                            {parsedBalances[cryptoName][index].balance.balance === 0
                              ? "0"
                              : truncateNumber(parsedBalances[cryptoName][index].balance.balance,6)}{" "}{cryptoName}
                          </span>
                          </div>
                        </>
                      )}

                      
                    </div>
                  </div>
                  <button
                    onClick={() => handleExchange()}
                    className={styles.submitButton}
                  >
                    {t("TransferCard.submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.containerCard} >
          <div className={`${styles.connectionCardLoading} connecting`}>
            <div className={styles.rowConnection}>
              <div>
                <FiArrowLeft
                  className={styles.firstRowIcon}
                  onClick={returnBackForCrypto}
                />
              </div>
              <div>
                <p>{t("OperationCard.cardCryptoTitle")}</p>
              </div>
              <div>
                <AiOutlineClose
                  className={styles.firstRowIcon}
                  onClick={handleViewCard}
                />
              </div>
            </div>
            <div className={styles.columnConnectionLoading}>
              <div className={styles.loaderParent}>
                <p className={styles.pStyle}>
                  {t("TransferCard.confirmation1")}{" "}
                  <span style={{ color: "#001e66", fontWeight: 700 }}>
                  {t("TransferCard.confirmation2")}{" "}
                  </span>
                </p>
                <div className={styles.loader}>
                  <ClipLoader size={40} color="#001e66" />
                  <p style={{ marginLeft: 10, marginTop: 8 }}>Loading...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CryptoTransfer;
