import { useContext, useState } from "react";
import styles from "./styles/Impostazioni.module.css";
import { Helmet } from "react-helmet";
import SuccessPop from "../components/SuccessPop";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrlContext } from "../context/BaseUrlContext";
import { useAuth } from "../context/AuthProvider";
import FailurePopUp from "../components/FailurePopUp";
import ToggleSwitch from "../components/ToggleSwitch";
import { use2FA } from "../context/TwoFAProvider";
import GoogleAuthCard from "../components/GoogleAuthCard";
import Disactivate2FACard from "../components/Disactivate2FACard";
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const Impostazioni = ({ t, i18n, languages, handleLanguageChange }) => {
  const [passwordVisible, setPasswordVisible] = useState({
    privateKey: false,
    oldPassword: false,
    newPassword: false,
    repeatPassword: false,
  });
  const navigate = useNavigate();

  const {toggleIsActive,disactivateIsActive} = use2FA();
  const { token, setAuthToken } = useAuth();
  const { baseUrl } = useContext(BaseUrlContext);
  const [success, setSuccess] = useState(false);

  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmNewPwd, setConfirmNewPwd] = useState("");

  const [failure, setFailure] = useState(false);

  const CHANGE_PSW_URL = "/api/changePassword";

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    const v2 = PWD_REGEX.test(newPwd);
    console.log(v2);
    if (!v2) {
      setFailure("The new password must include uppercase and lowercase letters, a number, and a special character.");
      return;
    }
    try {
      const response = await axios.post(
        baseUrl + CHANGE_PSW_URL,
        {
          old_password: oldPwd,
          new_password: newPwd,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setSuccess(true);
    } catch (err) {
      if(err.response)
        setFailure(err.response.data.message)
      else{
        console.log(err)
        setFailure("Network Error, check your connection")
      }
    }
  };

  const handleOperation = () => {
    setAuthToken(null);
    navigate("/login");
  };
  const handleFailure = () => {
    setFailure(!failure);
  };

  const getPasswordInputType = (passwordName) => {
    return passwordVisible[passwordName] ? "text" : "password";
  };


  return (
    <>
      <Helmet>
        <title>{t("Settings.metaTitle")}</title>
        <meta name="description" content={t("Settings.metaDescription")} />
      </Helmet>
      {success && (
        <SuccessPop
          message={t("SuccessPopUp.SettingsSubTitle")}
          handleSuccess={handleOperation}
          t={t}
        />
      )}
      {failure && (
        <FailurePopUp message={failure} handleFailure={handleFailure} t={t} />
      )}

      {toggleIsActive && (
        <GoogleAuthCard t={t}/>
      )}

      {disactivateIsActive && (
        <Disactivate2FACard t={t}/>
      )}
        <div className={styles.privateKeys} style={{ paddingTop: 50 }}>
          {/*
            <div className={styles.privateKeysNonContainer}>
              
              <span className={styles.privateKeysNonContainer1}>
                <p className={styles.privateKeys1}>
                  <b className={styles.privateKeys2}>{t('Settings.privateKeysTitle')}</b>
                </p>
                <p className={styles.nonCondividereMai}>
                  {t('Settings.privateKeysWarning')}
                </p>
              </span>
            </div>
      
            <form className={styles.passwordLabel}>
              <input
                type={getPasswordInputType("privateKey")}
                placeholder={t('Settings.passwordPlaceholder')}
                className={styles.inputField}
              />
              <img
                className={styles.vectorIcon}
                alt=""
                src={getToggleImageSource("privateKey")}
                onClick={() => togglePasswordVisibility("privateKey")}
              />
            </form>
            <div className={styles.button}>{t('Settings.showPrivateKeysButton')}</div>
      */}
          
          { /* Change Language Buttons */}
          <div className={styles.privateKeysNonContainer}>
            <span className={styles.privateKeysNonContainer1}>
              <p className={styles.privateKeys1}>
                <b className={styles.privateKeys2}>
                  {t("Settings.changeLanguageTitle")}
                </b>
              </p>
              <p className={styles.nonCondividereMai}>
                {t("Settings.changeLanguagePrompt")} <br />{" "}
                {t("Settings.changeLanguagePrompt2")}
              </p>
            </span>
          </div>
          <div className={styles.divButton}>
            {Object.keys(languages).map((lan, i) => {
              return (
                <button
                  type="submit"
                  key={lan}
                  onClick={() => handleLanguageChange(lan)}
                  disabled={i18n.resolvedLanguage === lan}
                  className={`${styles.language_button} ${
                    i18n.resolvedLanguage === lan ? styles.active : ""
                  }`}
                >
                  {languages[lan].nativeName}
                </button>
              );
            })}
          </div>


          { /* 2FA Toggle */}
          <div className={styles.privateKeysNonContainer}>
            <span className={styles.privateKeysNonContainer1}>
              <p className={styles.privateKeys1}>
                <b className={styles.privateKeys2}>
                  {t("Settings.activate2FAtitle1")}
                </b>
              </p>
              <p className={styles.nonCondividereMai}>
                {t("Settings.activate2FAsubtitle1")} <br />{" "}
                {t("Settings.activate2FAsubtitle2")}
              </p>

            </span>
          </div>
          <ToggleSwitch t={t}/>


          { /* Change Password Form */}
          <div className={styles.privateKeysNonContainer4}>
            <span className={styles.privateKeysNonContainer1}>
              <p className={styles.privateKeys1}>
                <b className={styles.privateKeys2}>
                  {t("Settings.passwordsTitle")}
                </b>
              </p>
              <p className={styles.nonCondividereMai}>
                {t("Settings.changePasswordPrompt")}
              </p>
            </span>
          </div>

          <form>
            <div className={styles.divImpostazioni}>
              <div className={styles.passwordLabel}>
                <input
                  type={getPasswordInputType("oldPassword")}
                  placeholder={t("Settings.oldPasswordPlaceholder")}
                  className={styles.inputField}
                  required
                  value={oldPwd}
                  onChange={(e) => setOldPwd(e.target.value)}
                />
              </div>
              <div className={styles.passwordLabel}>
                <input
                  type={getPasswordInputType("newPassword")}
                  placeholder={t("Settings.newPasswordPlaceholder")}
                  className={styles.inputField}
                  value={newPwd}
                  required
                  onChange={(e) => setNewPwd(e.target.value)}
                />
              </div>
              <div className={styles.passwordLabel}>
                <input
                  type={getPasswordInputType("repeatPassword")}
                  placeholder={t("Settings.repeatPasswordPlaceholder")}
                  className={styles.inputField}
                  value={confirmNewPwd}
                  required
                  onChange={(e) => setConfirmNewPwd(e.target.value)}
                />
              </div>
              <div
                className={styles.button}
                onClick={handleSubmitChangePassword}
              >
                {t("Settings.changePasswordButton")}
              </div>
            </div>
          </form>
        </div>
    </>
  );
};

export default Impostazioni;
