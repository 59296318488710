import { useCallback } from "react";
import ContainerItem from "../components/ContainerItem";
import Faq from "../components/Faq";
import styles from "./styles/Supporto.module.css";
import SupportoContainer from "../components/SupportoContainer";
import EmailItem from "../components/EmailItem";
import { Helmet } from "react-helmet";
const Supporto = ({t}) => {

  const onFrameIconClick = useCallback(() => {
    window.open("facebook.com");
  }, []);

  const onFrameIcon1Click = useCallback(() => {
    window.open("twitter.com");
  }, []);

  const onFrameIcon2Click = useCallback(() => {
    window.open("instagram.com");
  }, []);

  return (
    <>
    <Helmet>
        <title>{t("Support.metaTitle")}</title>
        <meta name="description" content={t("Support.metaDescription")} />
    </Helmet>
      <div className={styles.contattiFaq}>
        <div className={styles.contatti}>
          <EmailItem t={t}/>
          <ContainerItem t={t}/>
          <SupportoContainer t={t}/>
        </div>
        <Faq t={t}/>
        <div className={styles.frameParent}>
          <img
            className={styles.frameChild}
            alt=""
            src="/frame-129.svg"
            onClick={onFrameIconClick}
          />
          <img
            className={styles.frameChild}
            alt=""
            src="/frame-130.svg"
            onClick={onFrameIcon1Click}
          />
          <img
            className={styles.frameChild}
            alt=""
            src="/frame-131.svg"
            onClick={onFrameIcon2Click}
          />
        </div>
      </div>
    </>
  );
};

export default Supporto;
